<template>
  <admin>
    <page-header
      :back="{
        to: { name: 'inventories' },
        text: trans('Inventories'),
      }"
    >
      <template v-slot:title><span v-text="trans('Upload Inventories')"></span></template>
    </page-header>

    <div class="row">
      <div class="col-lg-9 col-12">
        <v-card>
          <v-card-title>File Information</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-file-input
                    label="Upload File"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    outlined
                    hide-details
                    v-model="userUpload"
                    :rules="rules"
                  ></v-file-input>
                  <br />
                  <p>
                    Click <a :href="sampleFileUrl" download>here</a> to the download sample file.
                  </p>
                </v-col>
              </v-row>
            </v-form>
            <div class="d-flex justify-end my-6" flat tile>
              <v-btn
                large
                exact
                color="green darken-1"
                class="ma-1 white--text"
                @click="validate(false)"
                :loading="loadingSave"
                :disabled="loadingSave || loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save
              </v-btn>
              <v-btn
                large
                exact
                color="green lighten-1"
                class="ma-1 white--text"
                @click="validate(true)"
                :loading="loadingSaveAndExit"
                :disabled="loadingSave || loadingSaveAndExit"
              >
                <v-icon left>mdi-content-save</v-icon>
                Save and Exit
              </v-btn>
              <v-btn
                large
                exact
                color="warning"
                class="ma-1 white--text"
                :to="{ name: 'inventories' }"
              >
                <v-icon left>mdi-close</v-icon>
                Cancel
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </admin>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      userUpload: null,
      valid: true,
      loadingSave: false,
      loadingSaveAndExit: false,
      rules: [(v) => !!v || "File is required", (v) => (v && v.size > 0) || "File is required"],
      sampleFileUrl:
        process.env.VUE_APP_API_BASE_URL.replace("api/", "") + "sample-files/inventories.xlsx",
    };
  },
  methods: {
    ...mapActions({
      uploadInventory: "inventories/uploadInventory",
      showDialog: "dialog/show",
      hideDialog: "dialog/hide",
    }),

    async validate(isExit) {
      const isValid = this.$refs.form.validate();
      if (!isValid) {
        return;
      }

      if (isExit) {
        this.loadingSaveAndExit = true;
      } else {
        this.loadingSave = true;
      }

      await this.saveInventories(isExit);
    },
    
    async saveInventories(isExit) {
      this.showDialog({
        title: trans("Confirm Upload"),
        text: trans(`Are you sure you want to proceed uploading <strong>${this.userUpload.name}</strong>?`),
        buttons: {
          cancel: {
            text: trans("Cancel"),
            callback: () => {
              this.loadingSaveAndExit = false;
              this.loadingSave = false;
              this.hideDialog();
            },
          },
          action: {
            color: "info",
            text: trans("Submit"),
            callback: () => {
              this.confirmSaveInventories(isExit);
              this.hideDialog();
            },
          },
        },
      });
    },

    async confirmSaveInventories(isExit) {
      var formData = new FormData();
      formData.append("file", this.userUpload);
      
      await this.uploadInventory(formData).then((data) => {
        this.$refs.form.reset();
      }).catch((error) => {
        if (error.code === "ECONNABORTED") {
          this.$router.push({ name: "inventories" });
        } else {
          const message = error.response?.data.errors ? Object.values(error.response.data.errors).join(" ") : error.response.data.message;

          this.$store.dispatch("snackbar/show", {
            text: message
          });
        }
    });

      this.loadingSaveAndExit = false;
      this.loadingSave = false;
      if (isExit) {
        this.$router.push({ name: "inventories" });
      }
    },
  },
};
</script>

<style></style>
